import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import {
  completePay,
  getModelRatingOptions,
  getRatingOptions,
  postClientFeedbackApi,
  postModelFeedbackApi,
} from "../../Redux/Services/OtherServices";
import { getData, storageKey } from "../../Utility/Storage";
import { showToast } from "../../Utility";
import { InputField } from "../InputField";
import moment from "moment";

const FeedbackModal = (props) => {
  const {
    show,
    setShow,
    userFeedback,
    type,
    proposalId,
    projectId,
    role,
    setLoading,
    getAllJobsList,
    status,
    getProposalsList,
    feedbackType,
    rating,
    setRating,
  } = props;
  const dispatch = useDispatch();

  const [ratingOptions, setRatingOptions] = useState([]);
  const [message, setMessage] = useState("");

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const handleRating = (clickedItem, value) => {
    const existingItemIndex = rating.findIndex(
      (item) => item.label === clickedItem.label
    );
    if (existingItemIndex !== -1) {
      const updatedRating = [...rating];
      updatedRating[existingItemIndex] = { ...clickedItem, value: value };
      setRating(updatedRating);
    } else {
      const updatedRating = [...rating, { ...clickedItem, value: value }];
      setRating(updatedRating);
    }
  };
  useEffect(() => {
    if (setRating) {
      setRating([]);
    }
    getProjectRatingOptionList();
  }, []);

  const getProjectRatingOptionList = async () => {
    let res = "";
    if (
      loggedInUser?.user_data?.user_role === 12 ||
      loggedInUser?.user_data?.user_role === 14
    ) {
      res = await dispatch(getRatingOptions());
    } else {
      res = await dispatch(getModelRatingOptions());
    }
    if (res?.status == 200) {
      setRatingOptions(res?.results);
    }
  };

  const submitRating = async (val) => {
    if (rating?.length !== 5) {
      showToast("You must select rating from 1-5 for each category", "error");
      return;
    }
    let userId = await getData(storageKey?.USER_ID);
    let body = {
      user_id: userId,
      action: "complete_react",
      rating: val === "skip" ? [] : rating,
      message: val === "skip" ? "" : message,
      proposal_id: proposalId,
      project_id: projectId,
    };
    setLoading(true);
    let res = await dispatch(completePay(body));
    setLoading(false);
    if (res?.status == 200) {
      setShow(false);
      if (getProposalsList) {
        getProposalsList();
      }
    }
  };

  const submitClientRating = async (val) => {
    if (rating?.length !== 5) {
      showToast("You must select rating from 1-5 for each category", "error");
      return;
    }
    let userId = await getData(storageKey?.USER_ID);
    let body = {
      user_id: userId,
      rating: rating,
      message: message,
      proposal_id: proposalId,
      project_id: projectId,
    };
    setLoading(true);
    let res = await dispatch(postClientFeedbackApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setShow(false);
      if (getProposalsList) {
        getProposalsList();
      }
    }
  };

  const submitTalentRating = async (val) => {
    if (rating?.length !== 5 && val !== "skip") {
      showToast("You must select rating from 1-5 for each category", "error");
      return;
    }
    let userId = await getData(storageKey?.USER_ID);
    let body = {
      user_id: userId,
      rating: val === "skip" ? [] : rating,
      message: val === "skip" ? "" : message,
      proposal_id: proposalId,
      project_id: projectId,
    };
    setLoading(true);
    let res = await dispatch(postModelFeedbackApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setShow(false);
      if (getAllJobsList) {
        getAllJobsList("completed");
      }
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Job Feedback </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {userFeedback?.length
            ? userFeedback?.map((item, index) => {
                console.log(Number(item?.user_rating));

                return (
                  <div className="modal-body modal-body_content fooedbackModel">
                    <div className="top_model_cont">
                     
                      <div className="avatar-wrap-icon">
                        <div className="wt-username">
                          {/* <figure className="wt-userimg">
                            <img
                              src={item?.user_details?.profile_image?.url}
                              alt="Profile Avatar"
                            />
                          </figure> */}
                          <h3>{item?.project_title || ""}</h3>
                        </div>
                        <Rating
                          name="half-rating-read"
                          precision={0.5}
                          value={
                            item?.user_rating ? Number(item?.user_rating) : 0
                          }
                          readOnly
                          initialValue={
                            item?.user_rating ? Number(item?.user_rating) : 0
                          }
                          size={25}
                          allowFraction={true}
                        />
                      </div>
                      {item?.review_title ? (
                        <p>
                          {" "}
                          <i className="fa fa-quote-left"></i>
                          {item?.review_title || ""}
                          <i className="fa fa-quote-right"></i>
                        </p>
                      ) : undefined}
                    </div>
                    <div class="clientDetail">
                        <span>{`Hired by "${item?.user_details?.user_name || ""}"`}</span>
                        <span>{moment(item?.complete_date).format("DD MMMM YYYY")}</span>
                      </div>
                    <div className="rating_review_list">
                      {item?.review?.length
                        ? item?.review?.map((rate) => {
                            return (
                              <div>
                                <span>{rate?.label || ""}</span>
                                <span>
                                  {rate?.value
                                    ? Number(rate?.value)?.toFixed(2)
                                    : 0}
                                </span>
                              </div>
                            );
                          })
                        : undefined}
                    </div>
                  </div>
                );
              })
            : undefined}
        </div>

        {type == "Rating" ? (
          <div className="feedbackSubmit">
            <div className="rating_review_list">
              {ratingOptions?.map((item, index) => {
                return (
                  <div key={index}>
                    <span>{item?.value}</span>
                    <span>
                      <Rating
                        onClick={(value) => handleRating(item, value)}
                        initialValue={0}
                        size={25}
                        allowFraction={true}
                      />
                    </span>
                  </div>
                );
              })}
              <InputField
                placeholder="Write your feedback message"
                type="textarea"
                value={message}
                onChangeText={(e) => {
                  setMessage(e?.target?.value);
                }}
              />
            </div>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="footer-center">
        {type == "Rating" ? (
          <>
            <Button
              onClick={() => {
                if (
                  role &&
                  status &&
                  status !== "completed" &&
                  feedbackType === 2
                ) {
                  if (role === "client") {
                    submitRating("skip");
                  } else {
                    submitTalentRating("skip");
                  }
                }
                setShow(false);
              }}
            >
              {role && feedbackType === 2 && status && status !== "completed"
                ? "Skip And Pay"
                : "Skip"}
            </Button>
            <Button
              onClick={() => {
                if (role === "client" && feedbackType === 2) {
                  submitRating("submit");
                } else if (role === "client" && feedbackType === 1) {
                  submitClientRating();
                } else {
                  submitTalentRating("submit");
                }
              }}
            >
              Submit feedback
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackModal;
