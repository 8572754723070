// import React, { useContext, useEffect, useState } from "react";
// // import Img from "../img/img.png";
// // import Attach from "../img/attach.png";
// import {
//   arrayUnion,
//   doc,
//   serverTimestamp,
//   Timestamp,
//   updateDoc,
// } from "firebase/firestore";
// import { db, storage } from "../../Utility/firebase";
// import { v4 as uuid } from "uuid";
// import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
// import { AuthContext } from "../../Context/AuthContext";
// import { ChatContext } from "../../Context/ChatContext";
// import { set } from "firebase/database";

// const ChatInput = ({ setLoading }) => {
//   const [text, setText] = useState("");
//   const [img, setImg] = useState(null);
//   const [isTyping, setIsTyping] = useState(false);

//   const { currentUser } = useContext(AuthContext);
//   const { data } = useContext(ChatContext);

//   const handleSend = async () => {
//     if (img) {
//       const storageRef = ref(storage, uuid());
//       setLoading(true);
//       const uploadTask = uploadBytesResumable(storageRef, img);

//       uploadTask.on(
//         (error) => {
//           //TODO:Handle Error
//         },
//         () => {
//           getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
//             await updateDoc(doc(db, "chats", data?.chatId), {
//               messages: arrayUnion({
//                 id: uuid(),
//                 text,
//                 senderId: currentUser?.uid,
//                 date: Timestamp.now(),
//                 img: downloadURL,
//               }),
//             });
//           });
//         }
//       );
//     } else {

//       await updateDoc(doc(db, "chats", data?.chatId), {
//         messages: arrayUnion({
//           id: uuid(),
//           text,
//           senderId: currentUser?.uid,
//           date: Timestamp.now(),
//         }),
//       });
//     }

//     await updateDoc(doc(db, "userChats", currentUser?.uid), {
//       [data?.chatId + ".lastMessage"]: {
//         text,
//       },
//       [data?.chatId + ".date"]: serverTimestamp(),
//     });

//     await updateDoc(doc(db, "userChats", data?.user?.uid), {
//       [data?.chatId + ".lastMessage"]: {
//         text,
//       },
//       [data?.chatId + ".date"]: serverTimestamp(),
//     });
//     setLoading(false);
//     setText("");
//     setImg(null);
//   };

//   return (
//     <div className="input">
//       <input
//         type="text"
//         placeholder="Type something..."
//         onChange={(e) => {setText(e.target.value)

//         }}
//         value={text}
//       />
//       <div className="send">
//         {/* <img src={Attach} alt="" /> */}
//         <input
//           type="file"
//           style={{ display: "none" }}
//           id="file"
//           onChange={(e) => setImg(e.target.files[0])}
//         />
//         <label htmlFor="file">{/* <img src={Img} alt="" /> */}</label>
//         <button onClick={handleSend}>Send</button>
//       </div>
//     </div>
//   );
// };

// export default ChatInput;

import React, { useContext, useEffect, useState } from "react";
import {
  arrayUnion,
  deleteField,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Utility/firebase";
import { v4 as uuid } from "uuid";
import { AuthContext } from "../../Context/AuthContext";
import { ChatContext } from "../../Context/ChatContext";

const ChatInput = ({ setLoading }) => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const updateTypingStatus = async (chatId, isTyping) => {
    try {
      const otherUserId = data?.user?.uid;
      const typingStatusRef = doc(
        db,
        "typingStatus",
        `${otherUserId}_${currentUser?.uid}`
      );

      await setDoc(typingStatusRef, {
        typing: isTyping,
      });
    } catch (error) {
      console.error("Error updating typing status:", error);
    }
  };

  // const handleSend = async () => {
  //   if (!text) return; // Ensure there's something to send
  //   setLoading(true);

  //   try {
  //     // Create a new message object
  //     const newMessage = {
  //       id: uuid(),
  //       text,
  //       senderId: currentUser?.uid,
  //       receiverId:data?.user?.uid,
  //       date: Timestamp.now(),
  //       status: 'sent'
  //     };

  //     // ** Check if the chat exists in the current user's userChats **
  //     const currentUserChatRef = doc(db, "userChats", currentUser?.uid);
  //     const currentUserChatDoc = await getDoc(currentUserChatRef);

  //     if (!currentUserChatDoc.exists() || !currentUserChatDoc.data()?.[data?.chatId]) {
  //       // Re-add the chat metadata if it doesn't exist
  //       await updateDoc(currentUserChatRef, {
  //         [`${data?.chatId}.userInfo`]: {
  //           uid: data?.user?.uid,
  //           displayName: data?.user?.displayName,
  //           photoURL: data?.user?.photoURL,
  //         },
  //         [`${data?.chatId}.lastMessage`]: {
  //           text: text, // Show "Image" as the last message if an image was sent
  //         },
  //         [`${data?.chatId}.date`]: serverTimestamp(),
  //       });
  //     }

  //     // ** Check if the chat exists in the other user's userChats **
  //     const recipientChatRef = doc(db, "userChats", data?.user?.uid);
  //     const recipientChatDoc = await getDoc(recipientChatRef);

  //     if (!recipientChatDoc.exists() || !recipientChatDoc.data()?.[data?.chatId]) {
  //       // Re-add the chat metadata if it doesn't exist for the other user
  //       await updateDoc(recipientChatRef, {
  //         [`${data?.chatId}.userInfo`]: {
  //           uid: currentUser?.uid,
  //           displayName: currentUser?.displayName,
  //           photoURL: currentUser?.photoURL,
  //         },
  //         [`${data?.chatId}.lastMessage`]: {
  //           text: text,
  //         },
  //         [`${data?.chatId}.date`]: serverTimestamp(),
  //       });
  //     }

  //     // Update the `chats` collection with the new message
  //     await updateDoc(doc(db, "chats", data?.chatId), {
  //       messages: arrayUnion(newMessage),
  //     });

  //     setText(""); // Reset text input

  //   } catch (error) {
  //     console.error("Error sending message: ", error);
  //   } finally {
  //     setLoading(false);
  //     updateTypingStatus(data?.chatId, false); // Stop typing indicator after sending
  //   }
  // };

  console.log(data,"llllldatat");
  
  

  const handleSend = async () => {
    setLoading(true);

    const chatId = data?.chatId;  // The chat's ID
    const recipientId = data?.user?.uid;  // User A's UID
  
    // Add the new message logic...
  
    // Reset the deletion timestamp for User A so new messages can appear
    const userChatRef = doc(db, "userChats", recipientId);
    await updateDoc(userChatRef, {
      [`${chatId}.deletedAt`]: deleteField()  // Remove the deletedAt field to allow new messages
    });

    // If only text is sent
    await updateDoc(doc(db, "chats", data?.chatId), {
      messages: arrayUnion({
        id: uuid(),
        text,
        senderId: currentUser?.uid,
        date: Timestamp.now(),
        receiverId: data?.user?.uid,
        status: "sent",
      }),
    });

    // Update the last message for both users
    await updateDoc(doc(db, "userChats", currentUser?.uid), {
      [data?.chatId + ".lastMessage"]: {
        text,
      },
      [data?.chatId + ".date"]: serverTimestamp(),
    });

    await updateDoc(doc(db, "userChats", data?.user?.uid), {
      [data?.chatId + ".lastMessage"]: {
        text,
      },
      [data?.chatId + ".date"]: serverTimestamp(),
    });

    setLoading(false);
    setText("");
    updateTypingStatus(data?.chatId, false);
  };

  useEffect(() => {
    let typingTimeout;
    if (isTyping) {
      updateTypingStatus(data?.chatId, true);
      typingTimeout = setTimeout(() => {
        setIsTyping(false);
        updateTypingStatus(data?.chatId, false);
      }, 6000);
    }

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [isTyping, data?.chatId]);

  return (
    <div className="input">
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => {
          setText(e.target.value);
          setIsTyping(true);
        }}
        value={text}
      />
      <div className="send">
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          onChange={(e) => setImg(e.target.files[0])}
        />
        <label htmlFor="file"></label>
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default ChatInput;
