import { Images } from "../Constants/Images";
import { Login } from "./Popups";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import { routeName, showToast } from "../Utility";
import React, { useContext, useEffect, useState } from "react";
import { clearData, getData, storageKey, storeData } from "../Utility/Storage";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../Redux/Services/AuthServices";
import { GET_NOTIFICATION_BADGE } from "../API Services/Url";
import { Button, Modal } from "react-bootstrap";
import { Loader } from "./Loader";
import {
  getNotificationBadge,
  getNotificationList,
  updateNotifyStatus,
} from "../Redux/Services/OtherServices";
import { NotifPopup } from "./Popups/NotifPopUp";
import { loggedInUsedAction } from "../Redux/Actions/AuthActions";
import { AuthButton } from "../features/header";
import { partnerAccessPermissions } from "../Redux/Actions/PartnerActions";
import { signOut } from "firebase/auth";
import { auth, db } from "../Utility/firebase";
import { doc, setDoc } from "firebase/firestore";
import { AuthContext } from "../Context/AuthContext";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginModal, setLoginModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState("");

  const { handleFirebaseLogout } = useContext(AuthContext);

  const authData = useSelector((state) => state?.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [show, setShow] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState({
    notification: "0",
    chat: "0",
    badge: false,
  });

  const handlePermissions = async () => {
    let permissions = await getData(storageKey.ACCESS_BASED);
    if (permissions) {
      dispatch(partnerAccessPermissions(JSON.parse(permissions)));
    }
  };

  const getNotifCount = async () => {
    let userID = await getData(storageKey?.USER_ID);
    let body = {
      user_id: userID ? JSON?.parse(userID) : "",
    };
    let response = await dispatch(
      getNotificationBadge(body, GET_NOTIFICATION_BADGE)
    );

    // let resp = await dispatch(getNotificationBadge(body, GET_CHAT_BADGE));
    if (response?.status == 200) {
      setCount({
        ...count,
        // badge: response?.results.count_status,
        notification: response?.results?.count,
        // chat: resp?.results?.unread_count,
      });
    }
    // }
  };

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    setLoading(true);
    if (userID) {
      let body = {
        user_id: userID ? JSON?.parse(userID) : "",
      };
      let res = await dispatch(getUserDetail(body));
      setLoading(false);
      if (res?.status == 200) {
        storeData(
          storageKey.USER_DATA,
          JSON.stringify(res?.results?.user_data)
        );
        dispatch(loggedInUsedAction(res?.results ? res?.results : null));
        getNotifCount();
      }
    }
    setLoading(false);
  };

  const handleViewAction = async (notifId, msg, status, item) => {
    if (loggedInUser?.user_data?.user_id) {
      if (status === "true") {
        setModalData(msg);
        setIsModalOpen(true);
        setSelectedItem(item);
      } else {
        let body = {
          notification_id: notifId,
          user_id: loggedInUser?.user_data?.user_id || "",
          status: "update",
        };
        let res = await dispatch(updateNotifyStatus(body));
        if (res?.status === 200) {
          getNotificationHistory();
          setModalData(msg);
          getNotifCount();
          setIsModalOpen(true);
          setSelectedItem(item);
          // setNotificationData(res?.results);
        }
      }
    }
  };

  const logout = async () => {
    clearData();
    handleFirebaseLogout()
    dispatch(loggedInUsedAction(null));
    dispatch(partnerAccessPermissions(null));
    showToast("Logged out successfully !", "success");
    setShow(false);
    getUserData();
    // const userFirebase = auth?.currentUser;
    // if (userFirebase) {
    //   await setDoc(
    //     doc(db, "userStatus", userFirebase?.uid),
    //     { status: "offline" },
    //     { merge: true }
    //   );
    // }
    // Proceed with logout
    // await signOut(auth);
    // await signOut(auth);
    navigate(routeName?.HOME, { replace: true });
  };

  const dropItems = [
    // {
    //   key: 1,
    //   name: "Inbox",
    //   path: routeName?.INBOX,
    //   hide: accountApproval ? false : true,
    // },
    {
      key: 2,
      name: "Dashboard",
      path: routeName?.DASHBOARD,
      hide: false,
    },

    // {
    //   key: 3,
    //   name: "Edit Application",
    //   path: routeName?.EDIT_PROFILE,
    //   hide: false,
    // },
  ];

  const getNotificationHistory = async () => {
    let body = {
      user_id: loggedInUser?.user_data?.user_id || "",
    };
    let res = await dispatch(getNotificationList(body));
    if (res?.status === 200) {
      setNotificationData(res?.results);
    }
  };

  useEffect(() => {
    if (loggedInUser?.user_data?.user_id) {
      getNotificationHistory();
    } else {
      getUserData();
    }
  }, [loggedInUser]);

  useEffect(() => {
    handlePermissions();
  }, []);

  return (
    <>
      <Loader loading={authData?.isLoading} />
      <Navbar collapseOnSelect expand="lg" className="">
        <Container>
          <Navbar.Brand onClick={() => navigate(routeName?.HOME)}>
            <img src={Images?.logo} height={120} width={120} />
          </Navbar.Brand>

          <div class="mobNav">
            <AuthButton
              setShow={setShow}
              setLoginModal={setLoginModal}
              userId={
                loggedInUser?.user_data?.user_id
                  ? loggedInUser?.user_data?.user_id
                  : ""
              }
              dropItems={dropItems}
              notificationData={notificationData}
              handleViewAction={handleViewAction}
              type="mobile"
              userRole={
                loggedInUser?.user_data?.user_role
                  ? Number(loggedInUser?.user_data?.user_role)
                  : ""
              }
              loading={authData?.isLoading || loading}
              setLoading={setLoading}
              count={count}
            />

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </div>

          <Navbar.Collapse
            id="responsive-navbar-nav "
            className="flex-row-reverse desktopNav"
          >
            <AuthButton
              setShow={setShow}
              setLoginModal={setLoginModal}
              userId={
                loggedInUser?.user_data ? loggedInUser?.user_data?.user_id : ""
              }
              dropItems={dropItems}
              notificationData={notificationData}
              handleViewAction={handleViewAction}
              type="pc"
              userRole={
                loggedInUser?.user_data?.user_role
                  ? Number(loggedInUser?.user_data?.user_role)
                  : ""
              }
              loading={authData?.isLoading || loading}
              setLoading={setLoading}
              count={count}
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {isModalOpen && (
        <NotifPopup
          show={isModalOpen}
          setShow={setIsModalOpen}
          text={modalData}
          selectedItem={selectedItem}
        />
      )}

      {loginModal && (
        <Login
          show={loginModal}
          setShow={setLoginModal}
          getUserData={getUserData}
        />
      )}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title> Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You want to Log out for now. You can login to this account again.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Not Now
          </Button>
          <Button variant="primary" onClick={() => logout()}>
            Yes, Log Out
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
