import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { InputField } from "../InputField";
import moment from "moment";

export const CAncelModal = (props) => {
  const {
    setVisible,
    visible,
    handleDirectConfirm,
    isAskModel,
    description,
    setDescription,
    handleAskToClient,
    setIsAskModel,
    data,
    selected,
  } = props;

  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0 });
  const [isValidDate, setIsValidDate] = useState(false);

  console.log(selected, "timeLefttimeLeft");

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const timer = () => {
    const targetDate = new Date(
      `${selected?.post_meta_details?.starting_date} ${
        loggedInUser?.user_data?.user_role === 12 ||
        loggedInUser?.user_data?.user_role === 14  ? selected?.post_meta_details?.format_start_time
        : selected?.post_meta_details?.starting_time
      }`
    );
    const now = new Date();

    if (targetDate > now) {
      // If the target date is in the future, update the countdown
      const totalTimeLeft = targetDate - now; // Difference in milliseconds

      // Convert milliseconds to days, hours, and minutes
      const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);

      setTimeLeft({ days, hours, minutes });
      setIsValidDate(true);
    } else {
      // If the target date is not in the future, reset the countdown
      setTimeLeft({ days: 0, hours: 0, minutes: 0 });
      setIsValidDate(false);
    }
  };

  useEffect(() => {
    timer();
  }, [selected]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
        setIsAskModel(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cancellation Policy
          <h6>
            {isValidDate
              ? `The job will start in  ${
                  timeLeft?.days ? `${timeLeft?.days} days` : ""
                } ${timeLeft?.hours ? `${timeLeft?.hours} hours` : ""}  ${
                  timeLeft?.minutes ? `${timeLeft?.minutes} minutes` : ""
                }`
              : ""}
          </h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="jobCancel">
        {loggedInUser?.user_data?.user_role === 12 ||
        loggedInUser?.user_data?.user_role === 14 ? (
          <div>
            <div className="form-group">
              <p className="rejReason">
                {data?.ask_cancel_project_decline?.data?.status ? (
                  <>
                    <span>
                      Your request for job cancellation has reject by talent.
                    </span>
                    <span>
                      <b>Reason : </b>{" "}
                      {data?.ask_cancel_project_decline?.data?.reject_reason}
                    </span>
                  </>
                ) : data?.ask_cancel_project_pending?.data?.status ===
                  "pending" ? (
                  <span>
                    Your job cancellation request is pending. Please wait until
                    talent is taking action into it.
                  </span>
                ) : (
                  <span>
                    *You may cancel the job by selecting, "Cancellation Request"
                    with the talent. If they agree to the cancellation, there
                    will be no penalties besides transaction fees.
                  </span>
                )}
              </p>
              {!isAskModel ? (
                <div className="footer-center">
                  <Button
                    onClick={() => {
                      setIsAskModel(true);
                    }}
                    className={
                      data?.ask_cancel_project_decline?.data?.status &&
                      data?.ask_cancel_project_pending?.data?.status ===
                        "pending"
                        ? "btn_disable"
                        : ""
                    }
                    disabled={
                      data?.ask_cancel_project_decline?.data?.status ||
                      data?.ask_cancel_project_pending?.data?.status ===
                        "pending"
                    }
                  >
                    {data?.ask_cancel_project_decline?.data?.status
                      ? "Request Declined"
                      : data?.ask_cancel_project_pending?.data?.status ===
                        "pending"
                      ? "Request Submitted"
                      : "Request to cancel"}
                  </Button>
                </div>
              ) : undefined}

              {isAskModel ? (
                <InputField
                  placeholder="Write your reason message to cancel this project"
                  type="textarea"
                  value={description}
                  onChangeText={(e) => {
                    setDescription(e?.target?.value);
                  }}
                />
              ) : undefined}

              {isAskModel ? (
                <div className="footer-center">
                  <Button
                    onClick={() => {
                      handleAskToClient();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              ) : undefined}

              {isAskModel ? undefined : <div className="sepratorDiv">Or</div>}

              {isAskModel ? undefined : (
                <div>
                  <p>
                    <span>
                      * If you cancel the job 24 hours within the start date,
                      the model will receive 100% payment as a compensation.
                    </span>
                  </p>
                  <p>
                    <span>
                      * If the job is canceled between 48 hours and 24 hours
                      before the start date, there will be a 50% refund and the
                      model will receive 50% as a compensation.
                    </span>
                  </p>
                  <p>
                    <span>
                      If the job is canceled more than 48 hours before the start
                      date, there will be no charges deducted.
                    </span>
                  </p>
                </div>
              )}

              {isAskModel ? undefined : (
                <div className="footer-center">
                  <Button
                    onClick={() => {
                      handleDirectConfirm();
                    }}
                  >
                    Cancel Now
                  </Button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="form-group">
            <p className="rejReason">
              {data?.ask_cancel_project_decline?.data?.status ? (
                <>
                  {" "}
                  <span>
                    {`Your request for job cancellation has reject by client.`}
                  </span>
                  <span>
                    <b>Reason : </b>{" "}
                    {data?.ask_cancel_project_decline?.data?.reject_reason}
                  </span>
                </>
              ) : data?.ask_cancel_project_pending?.data?.status ===
                "pending" ? (
                <span>
                  Your job cancellation request is pending. Please wait until
                  client is taking action into it.
                </span>
              ) : (
                <span>
                  *You may cancel the job by selecting, "Cancellation Request"
                  with the client. If they agree to the cancellation, there will
                  be no penalties besides transaction fees.
                </span>
              )}
            </p>

            {isAskModel ? undefined : (
              <div className="footer-center">
                <Button
                  onClick={() => {
                    setIsAskModel(true);
                  }}
                  className={
                    data?.ask_cancel_project_decline?.data?.status &&
                    data?.ask_cancel_project_pending?.data?.status === "pending"
                      ? "btn_disable"
                      : ""
                  }
                  disabled={
                    data?.ask_cancel_project_decline?.data?.status ||
                    data?.ask_cancel_project_pending?.data?.status === "pending"
                  }
                >
                  {data?.ask_cancel_project_decline?.data?.status
                    ? "Request Declined"
                    : data?.ask_cancel_project_pending?.data?.status ===
                      "pending"
                    ? "Request Submitted"
                    : "Request to cancel"}
                </Button>
              </div>
            )}

            {isAskModel ? (
              <InputField
                placeholder="Write your reason message to cancel this project"
                type="textarea"
                value={description}
                onChangeText={(e) => {
                  setDescription(e?.target?.value);
                }}
              />
            ) : undefined}

            {isAskModel ? (
              <div className="footer-center">
                <Button
                  onClick={() => {
                    handleAskToClient();
                  }}
                >
                  Submit
                </Button>
              </div>
            ) : undefined}
            {isAskModel ? undefined : <div className="sepratorDiv">Or</div>}

            {isAskModel ? undefined : (
              <div>
                <p>
                  <span>
                    * If you cancel the job within 24 hours of the start date,
                    your rating will be reduced to 1 and 50% of the amount of
                    your next job on this platform will be deducted as
                    compensation or penalty.
                  </span>
                </p>
                <p></p>
              </div>
            )}

            {isAskModel ? undefined : (
              <div className="footer-center">
                <Button
                  onClick={() => {
                    handleDirectConfirm();
                  }}
                >
                  Cancel Now
                </Button>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      {/* <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            setIsAskModel(false);
            setVisible(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
