import React, { useContext, useEffect, useState } from "react";
import {
  doc,
  onSnapshot,
  updateDoc,
  deleteDoc,
  deleteField,
  serverTimestamp,
} from "firebase/firestore";

import { AuthContext } from "../../Context/AuthContext";
import { ChatContext } from "../../Context/ChatContext";
import { db } from "../../Utility/firebase";
import { useLocation } from "react-router-dom";
import { DeletePost } from "../../Components";
import UserStatus from "./UserStatus";

const Chats = ({ setLoading }) => {
  const [chats, setChats] = useState([]);
  const [typingStatus, setTypingStatus] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [chatId, setChatId] = useState(""); // To store the chat ID that we want to delete

  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const displayName = queryParams?.get("displayName");
  const uid = queryParams?.get("uid");
  const photoURL = queryParams?.get("photoURL");
  const register_by = queryParams?.get("register_by");

  

  const { currentUser } = useContext(AuthContext);
  const { dispatch, data } = useContext(ChatContext);

  

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(
        doc(db, "userChats", currentUser?.uid),
        async (docs) => {
          const chatData = docs.data();
          setChats(chatData);
          if (chatData) {
            Object.entries(chatData).forEach(async ([chatId, chat]) => {
              const chatRef = doc(db, "chats", chatId);
              const typingStatusRef = doc(
                db,
                "typingStatus",
                `${currentUser?.uid}_${chat?.userInfo?.uid}`
              );

              // Listen for new messages and update the delivery status
              onSnapshot(chatRef, (chatDoc) => {
                const messages = chatDoc.data()?.messages || [];
                messages.forEach(async (message) => {
                  if (
                    message.status === "sent" &&
                    message?.receiverId === currentUser?.uid
                  ) {
                    // Update message status to "delivered"
                    const messageIndex = messages.findIndex(
                      (m) => m.id === message.id
                    );
                    messages[messageIndex].status = "delivered";
                    await updateDoc(chatRef, { messages });
                  }
                });
              });

              // Track typing status
              onSnapshot(typingStatusRef, (typingDoc) => {
                if (typingDoc.exists()) {
                  setTypingStatus((prevStatus) => ({
                    ...prevStatus,
                    [chat?.userInfo?.uid]: typingDoc.data()?.typing,
                  }));
                }
              });
            });
          }
        }
      );
      return () => unsub();
    };

    if (currentUser?.uid) getChats();
  }, [currentUser?.uid]);

  const handleSelect = (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
  };

  const handleDeleteClick = (id) => {
    setChatId(id); // Store the chat ID to delete
    setIsDelete(true); // Show confirmation modal
  };

  // const handleDelete = async () => {
  //   try {
  //     setLoading(true);

  //     // Clear the chat's last message field without deleting the user info
  //     const userChatRef = doc(db, "userChats", currentUser?.uid);
  //     await updateDoc(userChatRef, {
  //       [`${chatId}.lastMessage`]: deleteField(),  // Remove the last message
  //       [`${chatId}.date`]: deleteField(),        // Optionally remove date
  //     });

  //     // Optionally, you could add a flag like "isDeleted"
  //     // await updateDoc(userChatRef, {
  //     //   [`${chatId}.isDeleted`]: true,
  //     // });

  //     // Close the modal and reset the state
  //     setIsDelete(false);
  //     setLoading(false);
  //     setChats((prevChats) => {
  //       const updatedChats = { ...prevChats };
  //       if (updatedChats[chatId]) {
  //         updatedChats[chatId].lastMessage = null; // Update local state accordingly
  //       }
  //       return updatedChats;
  //     });
  //   } catch (error) {
  //     console.error("Error deleting chat: ", error);
  //     setLoading(false);
  //   }
  // };

  const handleDelete = async () => {
    try {
      setLoading(true);

      // Store the timestamp of when the chat was deleted
      const userChatRef = doc(db, "userChats", currentUser?.uid);
      await updateDoc(userChatRef, {
        [`${chatId}.lastMessage`]: deleteField(), // Remove the last message
        [`${chatId}.date`]: deleteField(), // Optionally remove date
        [`${chatId}.deletedAt`]: serverTimestamp(), // Store the deletion timestamp
      });

      // Close the modal and reset the state
      setIsDelete(false);
      setLoading(false);
      setChats((prevChats) => {
        const updatedChats = { ...prevChats };
        if (updatedChats[chatId]) {
          updatedChats[chatId].lastMessage = null;
          updatedChats[chatId].deletedAt = Date.now(); // Update local state with deletion timestamp
        }
        return updatedChats;
      });
    } catch (error) {
      console.error("Error deleting chat: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (displayName) {
      handleSelect({
        displayName,
        uid,
        photoURL,
        register_by
      });
    }
  }, [displayName]);

  return (
    <div className="chats">
      {chats
        ? Object.entries(chats)
            ?.sort((a, b) => b[1].date - a[1].date)
            .map((chat) => {
              console.log(chat[1]?.userinfo,"chattttttt");
              
              const userInfo = chat[1]?.userInfo;
              const isTyping = typingStatus[userInfo?.uid]; // Check typing status

              if (!chat[1]?.lastMessage) {
                return null; // Skip if there's no last message (chat was "deleted")
              }

              return (
                <div
                  className={
                    chat?.[1]?.userInfo?.uid === data?.user?.uid
                      ? "userChat active"
                      : "userChat"
                  }
                  key={chat[0]}
                  onClick={() => handleSelect(chat?.[1]?.userInfo)}
                >
                 {chat?.[1]?.lastMessage?.text ? <UserStatus userId={userInfo?.uid} /> : undefined}
                  <img src={userInfo?.photoURL} alt="" />
                  <div className="userChatInfo">
                    <span style={{ color: "black" }}>
                      {userInfo?.displayName || ""}
                    </span>
                    <p>
                      {isTyping ? "typing..." : chat?.[1]?.lastMessage?.text}
                    </p>
                  </div>
                  <i
                    onClick={() => handleDeleteClick(chat[0])}
                    className="fa fa-trash"
                    aria-hidden="true"
                  ></i>
                </div>
              );
            })
        : undefined}

      {isDelete ? (
        <DeletePost
          setShow={setIsDelete}
          show={isDelete}
          title="Delete Chat"
          handleConfirm={handleDelete}
          text="Are you sure you want to delete the chat with this user?"
        />
      ) : undefined}
    </div>
  );
};

export default Chats;
