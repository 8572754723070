// import React, { useContext, useEffect, useState } from "react";
// import Messages from "./Messages";
// import { ChatContext } from "../../Context/ChatContext";
// import ChatInput from "./ChatInput";
// import { doc, onSnapshot, updateDoc } from "firebase/firestore";
// import { db } from "../../Utility/firebase";
// import { AuthContext } from "../../Context/AuthContext";

// const ChatBar = ({ setLoading }) => {
//   const { data } = useContext(ChatContext);
//   const { currentUser } = useContext(AuthContext);
//   const [isTyping, setIsTyping] = useState(false);

//   useEffect(() => {
//     if (data?.chatId && data?.user?.uid) {
//       const otherUserId = data?.user?.uid;
//       const typingStatusRef = doc(
//         db,
//         "typingStatus",
//         `${currentUser?.uid}_${otherUserId}`
//       );

//       const unsubscribe = onSnapshot(typingStatusRef, (doc) => {
//         console.log(doc.exists());

//         if (doc.exists()) {
//           setIsTyping(doc.data()?.typing || false);
//         } else {
//           setIsTyping(false);
//         }
//       });

//       return () => unsubscribe();
//     }
//   }, [data?.chatId, data?.user?.uid]);

//   useEffect(() => {
//     if (data?.chatId && data?.user?.uid) {
//       const chatRef = doc(db, "chats", data?.chatId);

//       const unsubscribe = onSnapshot(chatRef, (chatDoc) => {
//         const messages = chatDoc.data()?.messages || [];

//         messages.forEach(async (message) => {
//           if (message.receiverId === currentUser.uid && message.status !== "read") {
//             // Update the status to "read"
//             const messageIndex = messages.findIndex((m) => m.id === message.id);
//             messages[messageIndex].status = "read";
//             await updateDoc(chatRef, { messages });
//           }
//         });
//       });

//       return () => unsubscribe();
//     }
//   }, [data?.chatId, currentUser?.uid]);

//   return (
//     <div className="chat">
//       {data?.user?.uid ? (
//         <>
//           <div className="wt-dashboardboxtitle currentChat">
//             <img src={data?.user?.photoURL} />
//             <div className="typingStatus">
//               <h2>{data?.user?.displayName} </h2>
//               {isTyping && <span>Typing...</span>}
//             </div>
//           </div>
//           <Messages setLoading={setLoading} />
//           <ChatInput setLoading={setLoading} />
//         </>
//       ) : (
//         <div className="wt-dashboardboxtitle currentChat">
//           <span>Go Ahead and start the conversation</span>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ChatBar;

import React, { useContext, useEffect, useState } from "react";
import Messages from "./Messages";
import ChatInput from "./ChatInput";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../../Utility/firebase";
import { AuthContext } from "../../Context/AuthContext";
import { ChatContext } from "../../Context/ChatContext";
import { useDispatch } from "react-redux";
import { getUserByUid } from "../../Redux/Services/OtherServices";

const ChatBar = ({ setLoading }) => {
  const { data } = useContext(ChatContext);
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);
  const [isTyping, setIsTyping] = useState(false);
  const [status, setStatus] = useState("offline");
  const [userRequest, setUserRequest] = useState("");

  // console.log(data,"datadatadatadata")

  const getUserData = async () => {
    let body = {
      chat_udi: data?.user?.uid,
    };
    try {
      setLoading(true);
      const res = await dispatch(getUserByUid(body));
      setLoading(false);
      if (res?.status === 200) {
        setUserRequest(res?.results?.register_by);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.user?.uid) {
      getUserData();
      const statusRef = doc(db, "userStatus", data?.user?.uid);
      const unsubscribe = onSnapshot(statusRef, (statusDoc) => {
        if (statusDoc.exists()) {
          setStatus(statusDoc.data()?.status || "offline");
        } else {
          setStatus("offline");
        }
      });

      return () => unsubscribe();
    }
  }, [data?.user?.uid]);

  useEffect(() => {
    if (data?.chatId && data?.user?.uid) {
      const otherUserId = data?.user?.uid;
      const typingStatusRef = doc(
        db,
        "typingStatus",
        `${currentUser?.uid}_${otherUserId}`
      );

      const unsubscribeTyping = onSnapshot(typingStatusRef, (doc) => {
        if (doc.exists()) {
          setIsTyping(doc.data()?.typing || false);
        } else {
          setIsTyping(false);
        }
      });

      return () => unsubscribeTyping();
    }
  }, [data?.chatId, data?.user?.uid]);

  useEffect(() => {
    if (data?.chatId && data?.user?.uid) {
      const chatRef = doc(db, "chats", data?.chatId);

      const unsubscribe = onSnapshot(chatRef, (chatDoc) => {
        const messages = chatDoc.data()?.messages || [];

        messages.forEach(async (message) => {
          if (
            message.receiverId === currentUser.uid &&
            message.status !== "read"
          ) {
            // Update the status to "read"
            const messageIndex = messages.findIndex((m) => m.id === message.id);
            messages[messageIndex].status = "read";
            await updateDoc(chatRef, { messages });
          }
        });
      });

      return () => unsubscribe();
    }
  }, [data?.chatId, currentUser?.uid]);

  return (
    <div className="chat">
      {data?.user?.uid ? (
        <>
          <div className="wt-dashboardboxtitle currentChat">
            <img src={data?.user?.photoURL} />
            <div className="typingStatus">
              <h2>{data?.user?.displayName} </h2>
              {isTyping ? (
                <span>Typing...</span>
              ) : (
                <span
                style={{
        color:
          status === "online" ? "green" : status === "away" ? "orange" : "gray",
      }}
                >
                  {userRequest === "self"
                    ? status === "online" ? "Online" : status === "away" ? "Away" : "Offline"
                    : ""}
                </span>
              )}
            </div>
          </div>
          <Messages setLoading={setLoading} />
          <ChatInput setLoading={setLoading} />
        </>
      ) : (
        <div className="wt-dashboardboxtitle currentChat">
          <span>Go Ahead and start the conversation</span>
        </div>
      )}
    </div>
  );
};

export default ChatBar;
